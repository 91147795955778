<template>
  <v-container id="login-view" class="fill-height" tag="section">
    <v-row justify="center">
      <v-col cols="12" md="9" lg="6">
        <v-slide-y-transition appear>
          <v-card class="pa-3 pa-md-10 mx-sm-auto" light>
            <!-- <h1 class="text-center text-h2 font-weight-light">Logowanie</h1> -->
            <template #heading>
              <div class="text-center pa-5">
                <v-list-item-title class="text-h3">
                  <strong class="mr-1 white--text font-weight-black">{{
                    $t("app.name")
                    }}</strong>
                </v-list-item-title>
              </div>
            </template>

            <v-form ref="form" autocomplete="on" v-model="valid" @submit.prevent="signin">
              <v-card-text class="text-center">
                <div class="text-center font-weight-light"></div>

                <v-text-field class="mt-10" id="user-text-field" autocomplete="username" color="secondary"
                  v-model="username" hide-details :label="$t('signin.username')" :placeholder="$t('signin.username')" />

                <v-text-field color="secondary" v-model="password" :label="$t('signin.password')"
                  :placeholder="$t('signin.password')" autocomplete="current-password" hide-details type="password"
                  v-on:keyup.enter="signin" />
                <v-col cols="12" class="ma-0 mb-5" align="right">
                  <router-link class="text-right" :to="{ name: 'PasswordReset' }">
                    {{ $t("signin.forget.your.password") }}
                  </router-link>
                </v-col>
                <v-btn color="secondary" depressed rounded large :disabled="!valid"  type="submit" class="mr-2">
                  {{ $t("signin.button") }}
                </v-btn>
                <v-btn color="secondary" v-if="true" rounded text large @click="signup">
                  {{ $t("signup.button") }}
                </v-btn>
              </v-card-text>
              <v-card-actions> </v-card-actions>
            </v-form>
          </v-card>
        </v-slide-y-transition>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from "vuex"
import vue from "vue"
// import router from '@/router'
export default {
  name: "LoginView",

  data: () => ({
    socials: [
      {
        href: "#",
        icon: "mdi-facebook"
      },
      {
        href: "#",
        icon: "mdi-twitter"
      },
      {
        href: "#",
        icon: "mdi-github"
      }
    ],
    valid: true,
    password: "",
    username: ""
  }),

  methods: {
    ...mapActions("auth", ["authenticationUser"]),
    ...mapActions("user", ["fetchUser", "update"]),
    signin() {
      if (this.$refs.form.validate()) {
        // loginInTheApplication(this.username, this.password)
        //   .then(async success => {
        //     console.log("login ok")
        //     await dispatch('authorize', success.data)
        // })

        const dispose = this.$store.subscribe(mutation => {
          if (mutation.type != "user/first_name") return

          dispose()
        })
        this.authenticationUser({
          email: this.username,
          password: this.password
        })
          .then(() => {
            vue.$log.info("log in success")
            this.$router.push({ name: "Events" })
          })
          .catch(error => {
            console.log("Incorect login/password", error.response)
            let message = error
            if (error.response.status == 401) {
              message = error.response.data.detail
            }
            this.$dialog.notify.error(message, {
              timeout: 10000
            })
          })
      }
      return false
    },
    signup() {
      this.$router.push({ name: "SignUp" })
    }
    // loginMock: function () {
    //
    //   this.$store.dispatch(AUTH_REQUEST, { username, password }).then(() => {
    //     this.$router.push('/')
    //   })
    // }
  }
}
</script>
